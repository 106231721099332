import { Component, OnInit } from '@angular/core';
import { Autoplay, EffectCoverflow, SwiperOptions } from 'swiper';
import { ApiService } from  '../_services/api.service';
import SwiperCore, {
  Navigation,
  Pagination,
} from 'swiper/core';

SwiperCore.use([Navigation, Pagination, EffectCoverflow, Autoplay]);

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {

  swipeDataOpt: SwiperOptions = {
   
    navigation: {
      nextEl: '.swiper-next-tab',
      prevEl: '.swiper-prev-tab'
    }
  }

  partners: any;

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.fetchPartners();
  }

  fetchPartners() {
    this.apiService.getPartners().toPromise().then((data) => {
      this.partners = data['data'];
      //console.log(this.partners);
    }).catch((err) => {
      console.log(err);
    });

  }

}
