import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../_services/api.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.css']
})
export class StoryComponent implements OnInit, OnDestroy {
  url: string;
  id: number;
  private sub: any;
  storyData: any;

  constructor(private route: ActivatedRoute, private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.url = this.router.url;
    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id'];
      this.loadSuccessStory(this.id);
   });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  loadSuccessStory(id: number) {
    this.apiService.getSuccessStory(id).toPromise().then((response: any[]) => {
      this.storyData = response['data'];
    }
    ).catch((error: any) => {
      window.alert('Error loading Success Story');
      this.router.navigate(['/stories']);
    });
  }

}
