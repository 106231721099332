import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { ActivatedRoute } from '@angular/router';
import { HelperService } from '../_services/helper.service';

@Component({
  selector: 'app-litem',
  templateUrl: './litem.component.html',
  styleUrls: ['./litem.component.css']
})
export class LitemComponent implements OnInit, OnDestroy {

  
  id: number;
  auth: any;
  private sub: any;
  lmCatData: any;
  lmData: any;
  pagination: any;

  constructor(private route: ActivatedRoute, private apiService: ApiService, private helper: HelperService) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id'];
      this.authStatus();
      this.loadCategoryData(this.id);
      this.loadByCategory(this.id);
   });

   
  }

  // search($event) {
  //   console.log(this.searchq)
  //   this.apiService.getLmatSearch(this.searchq).subscribe((data: any[]) => {
  //     this.lmData = data;
  //    //console.log(this.litems)
  //   })
  // }

  loadCategoryData(id: number) {
    this.apiService.getLearningMaterialCategories(id).toPromise().then((response: any[]) => {
      this.lmCatData = response['data'];
    } ).catch((error: any) => {
      window.alert('Error loading Category');
    });
  }

  loadByCategory(id: number, page: number = 1) {
    let limit = 6;
    this.apiService.getLearningMaterialsByCategory(id, page).toPromise().then((response: any[]) => {
      let lm = response['data'];
      let pageCount = response['meta']['pagination']['pageCount'];
      let pageArray = Array(pageCount).fill(0).map((x, i) => i + 1);
      this.pagination = {response: response['meta']['pagination'], pages: pageArray};
      //console.log(this.pagination);
      this.lmData = lm;
    }).catch((error: any) => {
      window.alert('Error loading Learning Materials');
    });  
  }

  authStatus() {
    this.apiService.getAuthStatus().toPromise().then((response: any[]) => {
      this.auth = true;
    }).catch((error: any) => {
      this.auth = false;
    });
  }


  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
