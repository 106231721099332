import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../_services/api.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class EventComponent implements OnInit, OnDestroy {
  id: number;
  private sub: any;
  eventData: any;

  constructor(private route: ActivatedRoute, private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {

    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id'];

      // Loading event data.
      this.apiService.getEvent(this.id).toPromise().then((response: any) => {
        this.eventData = response['data'];
        console.log(this.eventData);
      }).catch((error: any) => {
        window.alert('Error loading Event');
        this.router.navigate(['events']);
      });
      
   });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }


}



