// Global animation file.
// This file is used to define all the animations used in the application.
// This file is imported in the app.module.ts file.

import { trigger, state, style, animate, transition, query, stagger } from '@angular/animations';

// Stagger animation for the cards in the storylist component.
export const cardAnimation = trigger('listAnimation', [
    transition('* => *', [ // each time the binding value changes
      query(':leave', [
        stagger(100, [
          animate('0.5s', style({ opacity: 0 }))
        ])
      ]),
      query(':enter', [
        style({ opacity: 0 }),
        stagger(100, [
          animate('0.5s', style({ opacity: 1 }))
        ])
      ])
    ])
  ]);


