import { Component, OnInit } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { HelperService } from '../_services/helper.service';

@Component({
  selector: 'app-tfhome',
  templateUrl: './tfhome.component.html',
  styleUrls: ['./tfhome.component.css']
})
export class TfhomeComponent implements OnInit {

  auth: any;
  toolCats = [];
  toolItems = [];
  pagination: any;
  searchQuery: any;

  constructor(public apiService: ApiService, private helper: HelperService) { }

  ngOnInit(): void {
    this.authStatus();
    this.loadTools();
    this.loadCategories();
  }

  loadCategories() {
    this.apiService.getToolCategories().toPromise().then((response: any[]) => {
      let toolcats = response['data'];
      this.toolCats = toolcats;
    } ).catch((error: any) => {
      window.alert('Error loading Categories');
    } );
  }

  loadTools(page: number = 1) {
    let limit = 6;
    this.apiService.getTools(page, limit).toPromise().then((response: any[]) => {
      let tools = response['data'];
      let pageCount = response['meta']['pagination']['pageCount'];
      let pageArray = Array(pageCount).fill(0).map((x, i) => i + 1);
      this.pagination = {response: response['meta']['pagination'], pages: pageArray};
      //console.log(this.pagination);
      this.toolItems = tools;
    }).catch((error: any) => {
      window.alert('Error loading tools');
    });  
  }

  authStatus() {
    this.apiService.getAuthStatus().toPromise().then((response: any[]) => {
      this.auth = true;
    }).catch((error: any) => {
      this.auth = false;
    });
  }

  search($event) {
    this.apiService.searchTools(this.searchQuery).toPromise().then((response: any[]) => {
      this.toolItems = response['data'];
    }).catch((error: any) => {
      window.alert('Error loading tools');
    } );

  }

}
