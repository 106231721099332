import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../_services/api.service';
import { HelperService } from '../_services/helper.service';


@Component({
  selector: 'app-tfitem',
  templateUrl: './tfitem.component.html',
  styleUrls: ['./tfitem.component.css']
})
export class TfitemComponent implements OnInit, OnDestroy {

  id: number;
  auth: any;
  private sub: any;
  toolCatData: any;
  toolData: any;
  pagination: any;
  tfs: any;
  searchq: string;

  constructor(private route: ActivatedRoute, private apiService: ApiService, private helper: HelperService) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id'];
      this.authStatus();
      this.loadCategoryData(this.id);
      this.loadByCategory(this.id);
    
   });
  }

  loadCategoryData(id: number) {
    this.apiService.getToolCategories(id).toPromise().then((response: any[]) => {
      this.toolCatData = response['data'];
    } ).catch((error: any) => {
      window.alert('Error loading Category');
    });
  }

  loadByCategory(id: number, page: number = 1) {
    let limit = 6;
    this.apiService.getToolsByCategory(id, page).toPromise().then((response: any[]) => {
      let tools = response['data'];
      let pageCount = response['meta']['pagination']['pageCount'];
      let pageArray = Array(pageCount).fill(0).map((x, i) => i + 1);
      this.pagination = {response: response['meta']['pagination'], pages: pageArray};
      //console.log(this.pagination);
      this.toolData = tools;
    }).catch((error: any) => {
      window.alert('Error loading tools');
    });  
  }

  authStatus() {
    this.apiService.getAuthStatus().toPromise().then((response: any[]) => {
      this.auth = true;
    }).catch((error: any) => {
      this.auth = false;
    });
  }


  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
