import { Component, OnInit } from '@angular/core';
import { ApiService } from '../_services/api.service';



import { Autoplay, EffectCoverflow, SwiperOptions } from 'swiper';
import SwiperCore, {
  Navigation,
  Pagination,
} from 'swiper/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';

SwiperCore.use([Navigation, Pagination, EffectCoverflow, Autoplay]);

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})

export class LandingComponent implements OnInit {
  focus: any;
  focus1: any;

  public contactErrorMessageMain: any;
  public contactErrorMessages: any;
  public submittedContact: any;

  stories = [];
  news = [];
  events = [];

  sOptions: SwiperOptions = {
      
      slidesPerView: 3,
      autoplay: false,
      //centeredSlides: true,
      spaceBetween: 0,
      grabCursor: true,

      navigation: {
        prevEl: ".swiper-prev-story",
        nextEl: ".swiper-next-story"
      },
      
      breakpoints: {
        768: {
          slidesPerView: 3
        },
        576: {
          slidesPerView: 2
        },
        300: {
          slidesPerView: 1
        }
      }
      
  }

  options2: SwiperOptions = {
    loop: true,
    speed: 1000,
  }

  constructor(private apiService: ApiService) { }

  
  public onMessageSubmit(data){ 
    console.log(data);
    let message = {"data": data};
    this.apiService.createMessage(message).toPromise().then((response: any) => {
      this.submittedContact = true;
    }).catch((error: any) => {
      window.alert(error['error']['message']);
    });
  }

  ngOnInit() {
    this.loadSuccessStories();
    this.loadNewsItems();
    this.loadEvents();
  }

  loadSuccessStories() {
    this.apiService.getSuccessStories(1, 6).toPromise().then((response: any[]) => {
      let stories = response['data'];
      this.stories = stories;
    }).catch((error: any) => {
      window.alert('Error loading Success Stories');
    });
  }

  loadNewsItems() {
    this.apiService.getNews(1, 6).toPromise().then((response: any[]) => {
      let news = response['data'];
      // Get the first 4 news
      this.news = news.slice(0, 4);
      
    }).catch((error: any) => {
      window.alert('Error loading News Items');
    });
  }

  loadNews() {
    this.apiService.getNews().toPromise().then((response: any[]) => {
      //Get the first 4 news
      let news = response['data'].slice(0, 4);

      this.news = news;
    }).catch((error: any) => {
      window.alert('Error loading News');
    });
  }

  loadEvents() {
    this.apiService.getEvents().toPromise().then((response: any[]) => {
      //Get the first 4 events
      let events = response['data'].slice(0, 4);

      this.events = events;
      console.log(this.events)
    }).catch((error: any) => {
      window.alert('Error loading Events');
    });
  }

  simpleAlert() {
    Swal.fire({
      imageUrl: './assets/img/popup/E-flyer_Online_Sin_published.jpg',
      imageAlt: 'Advert image',
      showCloseButton: true,
      confirmButtonText: "Know More"
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        window.location.href = 'https://www.pwc.com/lk/en/assets/ida/RCT-Advert-Online-Sin.pdf';
      } 
    })

  }

}
