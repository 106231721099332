import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../_services/api.service';
import { Router } from '@angular/router';

import { Autoplay, EffectCoverflow, SwiperOptions } from 'swiper';
import SwiperCore, {
  Navigation,
  Pagination,
} from 'swiper/core';

SwiperCore.use([Navigation, Pagination, EffectCoverflow, Autoplay]);

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})
export class LogoComponent implements OnInit {

  

  public doners: any;
  public partners: any;

  //Swiper Options for Logos Carousel
  options: SwiperOptions = {
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    effect: 'coverflow',
    coverflowEffect: {
      rotate: 25,
      stretch: 0,
      depth: 300,
      modifier: 1,
      slideShadows: false,
    },
    slidesPerView: 5,
    spaceBetween: 0,
    grabCursor: true,
    breakpoints: {
      768: {
        slidesPerView: 3
      },
      576: {
        slidesPerView: 2
      },
      300: {
        slidesPerView: 1
      }
    }
  }




  constructor(private apiService: ApiService, private router: Router ) { }

  ngOnInit(): void {
    this.fetchDonors();
    this.fetchPartners();
  
  }

  fetchDonors() {
    this.apiService.getDonors().toPromise().then((data) => {
      this.doners = data['data'];
    }).catch((err) => {
      console.log(err);
    });

  }

  getPath(){
    return this.router.url;
  }

  fetchPartners() {
    this.apiService.getPartners().toPromise().then((data) => {
      this.partners = data['data'];
      //Getting the first 8 partners
      //this.partners = this.partners.slice(0, 8);

    }).catch((err) => {
      console.log(err);
    });

  }

}
